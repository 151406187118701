@import 'variables';
@import 'bootstrap.scss';
@import 'extensions.scss';
@import 'global.scss';

.App {
  background-color: transparent;
  font-family: Helvetica, Arial, sans-serif;

  .page-head {
    background-color: #fff;
    color: $primary-dark;
  }

  .page-body {
    background-color: #fff;
    color: $primary-dark;
    padding: 0;
  }

  .page-foot {

  }
}
