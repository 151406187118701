@import '../mixins';

.banner-container {
  padding: 0;
  background-color: #777;
  background-size: cover;
  color: #fff;
  font-size: 1.25rem;

  > .row {
    background-color: rgba(50,50,50,.75);

    .content-container {
      color: #000;
      padding-top: 2rem;
      padding-bottom: 2rem;
      background-color: rgba(255,255,255,.7);
    }
  }

  h3 {
    @extend %decorator-font-face;
    margin: 0 0 2rem 0;
  }

  &.offset-layout {
    > .row {
      .offset-wrapper {
        padding-top: 20rem;
        padding-bottom: 0rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    > .row {
      .content-container {
        padding: 2.5rem;
      }
    }
  }
}