@import '../mixins';

.footer-container {
  font-family: Helvetica, Arial, sans-serif;
  color: #bbb;
  padding: 3rem 0 0 1rem;
  border-top: 2px solid #555;

  a, a:active, a:visited, a:hover {
    color: #ff6600;
  }

  h5 {
    color: #ddd;
  }

  @include media-breakpoint-down(md) {
    > .row > .col-sm-12 + .col-sm-12 {
      margin-top: 2rem;
      border-top: 1px solid #999;
      padding-top: 2.5rem;
    }
  }
}