.subheader-container {
  padding: 1rem 2rem;
  background-color: #222;
  color: #fff;

  ul {
    margin: 0 auto;

    li {
      list-style-type: none;
      position: relative;
    }

    > li {
      display: inline-block;
    }

    > li + li {
      padding-left: 1.5rem;
    }
  }
}